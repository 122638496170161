import * as React from "react";
import { PageProps } from "gatsby";
import { graphql } from "gatsby";

import CollectionTemplate from "@shared/collectionTemplate";
import { BreadcrumbPageContext } from "@util/types";
import { Query } from "@graphql-types";

interface Props extends PageProps {
  pageContext: BreadcrumbPageContext;
  data: Query;
}

const ShopPage = ({ pageContext, data }: Props) => {
  const { allSanityCategory, allSanityCollection } = data;
  const pageData = data.allSanityShopAllLandingPage.nodes[0];
  const homePage = data.allSanityHomePage.nodes[0];

  return (
    <CollectionTemplate
      hero={pageData.hero}
      categoryList={allSanityCategory.nodes}
      collectionList={allSanityCollection.nodes}
      isShopAll={true}
      seo={pageData.seo}
      pageContext={pageContext}
      giftingBlock={homePage}
      iban={pageContext.iban}
    />
  );
};

export default ShopPage;

export const query = graphql`
  query ShopAllLandingQuery(
    $iban: String
    $shouldFetchAU: Boolean!
    $shouldFetchNZ: Boolean!
  ) {
    allSanityShopAllLandingPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        additionalHeros {
          ...sanityHero
        }
        featuredRecipes {
          id
        }
        seo {
          ...sanitySeo
        }
      }
    }
    allSanityCollection(
      sort: {fields: orderRank, order: ASC }
      filter: { main: { region: { iban: { eq: $iban } } } }
    ) {
      nodes {
        id
        _key
        ...sanityCollectionPreviewReduced
      }
    }
    allSanityHomePage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        gifting {
          ...sanityGiftingLanding
        }
        featuredRecipes {
          _id
          id
          info {
            ...sanityRecipeInfo
          }
        }
      }
    }
    allSanityCategory(
      sort: {fields: orderRank, order: ASC }
      filter: { main: { regions: { elemMatch: { iban: { eq: $iban } } } } }
    ) {
      nodes {
        categoryColour {
          _key
          _type
          title
          value
        }
        products @include(if: $shouldFetchNZ) {
          id
          _id
          content {
            main {
              title
              slug {
                current
              }
              featuredBadge {
                ...sanityAllergenOrProductBadge
              }
              featuredAllergen {
                ...sanityAllergenOrProductBadge
              }
              mainImage {
                asset {
                  gatsbyImageData(
                    width: 260
                    placeholder: BLURRED
                    formats: AUTO
                  )
                }
              }
            }
            shopify {
              defaultPrice
              defaultCompareAtPrice
              isSoldOut
              defaultVariant {
                variantId
              }
            }
            info {
              declaredWeight
              allergens {
                title
              }
            }
          }
        }
        productsAu @include(if: $shouldFetchAU) {
          id
          _id
          content {
            main {
              title
              slug {
                current
              }
              featuredBadge {
                ...sanityAllergenOrProductBadge
              }
              featuredAllergen {
                ...sanityAllergenOrProductBadge
              }
              mainImage {
                asset {
                  gatsbyImageData(
                    width: 260
                    placeholder: BLURRED
                    formats: AUTO
                  )
                }
              }
            }
            shopify {
              defaultPrice
              defaultCompareAtPrice
              isSoldOut
              defaultVariant {
                variantId
              }
            }
            info {
              declaredWeight
              allergens {
                title
              }
            }
          }
        }
        bundleProducts {
          ...sanityBundles
        }
        main {
          title
          slug {
            current
          }
        }
      }
    }
  }
`;
